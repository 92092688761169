/* make datepicker work nicely w/ material modal */

@import './antd-namespaced.min.css';

/* override chat header title font size */
.ce-chat-header-title {
    font-size: 1em !important;
    text-align: left;
    padding-left: 1em;
    padding-top: 0.75em;
}
.ce-chat-header-subtitle {
    text-align: left;
    padding-left: 1.4em;
}
/* mobile chat back to inbox button */
.ce-mobile-chat-list-button {
    /* hide from app since back button is implemented*/
    display: none !important;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    margin-top: 0.5em;
    background-color: teal !important;
}
.ce-mobile-chat-list-button span {
    display: none;
}

/* so send  button aligns better */
.ce-message-form-input {
    width: calc(100% - 115px) !important;
}

.ce-mobile-chat-list-button:after {
    content: 'BACK TO INBOX';
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    font-weight: 600;
    color: white;
    width: calc(100vw - 4em);
}

/* generically - no scroll bars */
/*
body::-webkit-scrollbar {
    display: none !important;
    scrollbar-width: none; 
    width: 0;
}
-webkit-scrollbar {
    display: none !important;
}
*/

/* chat window */
.ce-chat-engine ::-webkit-scrollbar {
    width: 0;
    background: transparent; /* Optional: just make scrollbar invisible */
}

/* chat msgs */
.ce-message-list::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
    border-radius: 30px;
}

.ce-message-list::-webkit-scrollbar {
    width: 10px;
    background-color: #f5f5f5;
}

.ce-message-list::-webkit-scrollbar-thumb {
    border-radius: 30px;
    background-color: #8895dc;
}

/* floating labels for text inputs for amplify */
/*
.amplify-input.amplify-field-group__control {
    height: 3.25em;
    padding-bottom: 0;
}
*/

/*
.amplify-label {
    position: absolute;
    top: 0px;
    left: 1.25em;
    font-size: 0.75em;
}
.amplify-flex.amplify-field.amplify-textfield {
    position: relative;
}
*/

/* center amplify login */
[data-amplify-authenticator] {
    display: flex;
    justify-content: center;
    /*height: 100vh;*/
    padding: 0.75em;
    overflow-x: hidden;

    /*
    --amplify-colors-background-primary: yellow;
    --amplify-colors-background-secondary: black;
    --amplify-colors-brand-primary-10: var(--amplify-colors-teal-100);
    --amplify-colors-brand-primary-80: var(--amplify-colors-teal-40);
    --amplify-colors-brand-primary-90: var(--amplify-colors-teal-20);
    --amplify-colors-brand-primary-100: var(--amplify-colors-teal-10);
    --amplify-colors-font-interactive: var(--amplify-colors-white);
    --amplify-components-tabs-item-active-color: black;
    --amplify-components-tabs-item-focus-color: purple;
    --amplify-components-tabs-item-hover-color: green;
    --amplify-components-tabs-item-background-color: orange;
    --amplify-components-tabs-background-color: light-purple;
    */
}

/* signup/signin modal is at top */
[data-amplify-authenticator] [data-amplify-container] {
    place-self: start;
}

/*inactive tabe*/
[data-amplify-authenticator] [data-state='inactive'] {
    background-color: var(--amplify-colors-purple-10);
    /*
    border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
    */
    border-top-color: var(--amplify-colors-purple-20);
}

/* calendar day view top left foramtting */
.rbc-label.rbc-time-header-gutter {
    padding: 0;
}

/*color the current date */
.rbc-now .rbc-button-link {
    /*background-color: #d5f0ee;*/
    background-color: #7985cb;
    color: white;
    border-radius: 20%;
    padding-left: 0.15em;
    padding-right: 0.15em;
    margin-top: 0.1em;
}

/* background color of today cell is white */
.rbc-day-bg.rbc-today {
    background-color: white;
}
/* calendar text is multi-line*/
.rbc-ellipsis,
.rbc-show-more,
.rbc-row-segment .rbc-event-content,
.rbc-event-label {
    white-space: pre-wrap !important;
}

/* end calendar formatting */

.ant-picker-dropdown {
    z-index: 10000 !important;
}

.ant-picker-time-panel-column {
    overflow-x: hidden;
}
.ant-picker-now-btn {
    cursor: pointer;
    color: #1890ff;
}
.ant-picker-today-btn {
    cursor: pointer;
}

.ant-picker {
    border-radius: 4px;
}
.ant-picker:hover {
    border-color: black;
}
.ant-picker-focused,
.ant-picker-focused.ant-picker:hover {
    border-color: #3f51b5;
    outline: -webkit-focus-ring-color auto 1px;
    outline-color: #3f51b5;
    outline-style: auto;
    outline-width: 1px;
    box-shadow: none;
}

.ant-picker:active {
    border-color: #3f51b5;
}

/* end date picker mods */

:root {
    --amplify-primary-color: teal;
}
.App {
    /*text-align: center;*/
    min-height: 100vh;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
    min-height: 100vh;
}

/*
amplify-sign-in,
amplify-confirm-sign-up,
amplify-sign-up {
    width: 100vw;
    margin-top: 8em;
    min-height: 100vh;
    display: inline-block;
    text-align: center;
}
*/

.signupTerms {
    top: -0.5em !important;
    position: relative;
}
/*
amplify-authenticator {
    position: relative;
    min-height: 100vh;
}
*/

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

/* mobile calendar toolbar formatting */
@media only screen and (max-width: 1023px) {
    /* iphone bouncy phone only*/
    [data-amplify-authenticator] {
        max-height: 90vh;
        overflow-y: scroll;
    }
    /*hide scroll bars*/
    [data-amplify-authenticator]::-webkit-scrollbar {
        display: none;
    }
    /*hide scroll bars*/
    [data-amplify-container]::-webkit-scrollbar {
        display: none;
    }

    [data-amplify-container] {
        height: 130vh;
    }
    /*end iphone bouncy */

    /*
    .rbc-toolbar .rbc-today-btn {
        display: none;
    }
    */
    .rbc-toolbar .rbc-toolbar-label {
        text-align: right;
        padding: 0;
    }
    .rbc-toolbar .rbc-btn-group {
        margin-top: 0.25em;
        margin-bottom: 1.5em;
    }
    .rbc-ellipsis,
    .rbc-event-label,
    .rbc-row .rbc-row-segment .rbc-event-content,
    .rbc-row .rbc-show-more {
        /*white-space: normal;*/
        font-size: 0.7em;
    }
    /* fix weekly calendar table not formatting correctly on mobile */
    /*.rbc-agenda-view table.rbc-agenda-table .rbc-agenda-date-cell,*/
    .rbc-agenda-view table.rbc-agenda-table .rbc-agenda-time-cell,
    .rbc-agenda-view table.rbc-agenda-table .rbc-agenda-date-cell,
    .rbc-agenda-view table.rbc-agenda-table .rbc-agenda-event-cell {
        padding-left: 4px !important;
        padding-right: 4px !important;
    }
}
